<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分 -->
        <div class="top_btn">
            <!-- 页面标题部分 -->
            <div class="page_name">
                <div class="sig">
                    <i class="el-icon-arrow-left" @click="handle_goback"></i>
                </div>
                <div class="sig_name">{{title}}</div>
            </div>
            <div class="btn_cl"></div>
        </div>
        <!-- 输入框部分 -->
        <div class="from_main">
            <el-form label-position="left" label-width="118px">
                <el-form-item label="姓名">
                    <el-input v-model="sales_name" placeholder="请输入业务员姓名" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio v-model="sex" label="1" @change="handleSex">男</el-radio>
                    <el-radio v-model="sex" label="2" @change="handleSex">女</el-radio>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="mobile" oninput="this.value = this.value.replace(/[^\d.]/g,'');"
                        placeholder="请输入业务员手机号" :clearable="true"></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="地区">
                    <el-input v-model="adress" placeholder="请输入业务员所在地区" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1" @change="stateFn">正常</el-radio>
                    <el-radio v-model="radio" label="2" @change="stateFn">禁用</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <!-- 提交重置按钮 -->
        <div class="btn_main">
            <el-button class="btn_rest" @click="ResterFn">重置</el-button>
            <el-button class="btn_submit" @click="SubmitFn">{{btn_name}}</el-button>
        </div>
    </div>
</template>

<script>
import { showSalesApi, addSalesApi, editSalesApi } from '@/api'
export default {
    data () {
        return {
            // 页面标题
            title: '',
            // 按钮名字
            btn_name: '',
            // 每条数据id
            id: '',
            // 有下级的id
            lower_id: '',
            // 登录用户id
            user_id: '',
            // 又没有下级
            lower: '',
            // 姓名
            sales_name: '',
            // 手机号
            mobile: '',
            // 性别
            sex: '1',
            sex_type: 1,
            // 地址
            adress: '',
            // 状态
            radio: '1',
            auth_state: 1,
        }
    },
    mounted () {
        this.id = this.$route.query.id;
        this.lower_id = this.$route.query.lowerId;
        this.user_id = sessionStorage.getItem('id');
        this.lower = sessionStorage.getItem('lower');
        if (this.id == '添加业务员') {
            this.title = '添加业务员';
            this.btn_name = '确认添加';
        } else {
            this.title = '编辑业务员';
            this.btn_name = '确认修改';
            this.edit_init();
        };
    },
    methods: {
        edit_init () {
            showSalesApi({
                lower_id: this.lower_id,  //有下级id
                id: this.id,
                auth_id: this.user_id,   //登录用户id
                has_lower: this.lower    //是否有下级
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.sales_name = res.data.name;  //姓名
                    this.mobile = res.data.phone;  //手机号
                    this.adress = res.data.area;   //地区
                    this.sex_type = res.data.sex;   // 联系人
                    if (this.sex_type == 1) {
                        this.sex = '1'
                    } else if (this.sex_type == 2) {
                        this.sex = '2'
                    };
                    this.auth_state = res.data.status;  //状态
                    if (this.auth_state == 1) {
                        this.radio = '1'
                    } else if (this.auth_state == 2) {
                        this.radio = '2'
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 状态单选
        handleSex () {
            if (this.sex == '1') {
                this.sex_type = 1;
            } else {
                this.sex_type = 2;
            }
        },
        // 状态单选
        stateFn () {
            if (this.radio == '1') {
                this.auth_state = 1;
            } else {
                this.auth_state = 2;
            }
        },
        // 点击重置按钮
        ResterFn () {
            this.sales_name = '';      //姓名
            this.sex = '1';     //性别
            this.mobile = '';    //手机号
            this.adress = '';  //地址
            this.radio = '1';     //状态
        },
        // 点击返回按钮返回
        handle_goback () {
            this.$router.push({ path: '/detail', query: { id: this.lower_id } })
        },
        // 点击提交按钮
        SubmitFn () {
            if (this.id == '添加业务员') {
                addSalesApi({
                    auth_id: this.user_id,  //登陆用户名
                    name: this.sales_name,    //姓名
                    phone: this.mobile,  // 手机号
                    sex: this.sex_type,   //性别
                    area: this.adress,   //地址
                    lower_id: this.lower_id,  //有下级id
                    has_lower: this.lower,  //又没有下级
                    status: this.auth_state,   //状态
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.$router.push({ path: '/detail', query: { id: this.lower_id } })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                editSalesApi({
                    id: this.id,
                    auth_id: this.user_id,  //登陆用户名
                    name: this.sales_name,    //姓名
                    phone: this.mobile,  // 手机号
                    sex: this.sex_type,   //性别
                    area: this.adress,   //地址
                    lower_id: this.lower_id,  //有下级id
                    has_lower: this.lower,  //又没有下级
                    status: this.auth_state,   //状态
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.$router.push({ path: '/detail', query: { id: this.lower_id } })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                margin-right: 6px;
                i {
                    font-size: 24px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
        .btn_cl {
            width: 10px;
        }
    }
    // 输入框部分
    .from_main {
        padding: 24px;
        .el-form {
            text-align: left;
            .el-form-item {
                width: 370px;
                position: relative;
                border: 1px solid #d9d9d9;
                border-radius: 6px;
                /deep/.el-form-item__label {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                }
                .el-input {
                    border: none;
                    width: 252px;
                    height: 44px;
                }
                /deep/.el-input__inner {
                    border: none;
                    width: 252px;
                    height: 44px;
                }
                .shugan {
                    background: #d3d3d6;
                    width: 2px;
                    height: 16px;
                    position: absolute;
                    right: 258px;
                    top: 12px;
                }
            }
        }
    }
    // 提交 重置按钮部分
    .btn_main {
        margin-top: 100px;
        text-align: left;
        padding: 0 20px 32px;
        .el-button {
            width: 160px;
            height: 40px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
        .btn_submit {
            border: none;
            background: #6a8be8;
            margin-left: 8px;
            color: #ffffff;
        }
        .btn_rest {
            border: 1px solid #d9d9d9;
            color: #20252b;
            background: #ffffff;
        }
    }
}
</style>